import {
    PhoneNumberFormat,
    PhoneNumberUtil,
} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const checkPhone = (phone, country) => {

    console.log({phone, country});
    

    const number = phoneUtil.parseAndKeepRawInput(phone, country);
    const typePhone = phoneUtil.getNumberType(number);
    const isPhoneValid = phoneUtil.isValidNumber(number);
    const isPhoneValidByRegion = phoneUtil.isValidNumberForRegion(number, country);
    let result = null;

    if (typePhone === 0) { // 0 - fijo | 1 - Celular.
        result = phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
    } else {
        result = phoneUtil.format(number, PhoneNumberFormat.E164);
    }

    return {
        getNumber: result,
        isAllowed: isPhoneValid && isPhoneValidByRegion ? true : false,
        typePhone: typePhone === 0 ? 0 : 1,
        indicativePhone: typePhone === 0 ? result.slice(0,3) : `+${number.getCountryCode()}`
    }
}